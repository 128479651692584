import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  Button,
  Col,
  Row,
  Table,
  Typography,
  ConfigProvider,
  Spin
} from "antd"
import {
  LinkOutlined,
  EditOutlined
} from "@ant-design/icons"
import { useDispatch, useSelector } from "react-redux"
import { getTodos, getDiaries } from "../store/actions"
import {
  getJobPendingStatus,
  getTodosSelector,
  getContactsSelector,
  getCompaniesSelector,
  getContactsPendingStatus,
  getCompaniesPendingStatus,
  getHomeJobs,
  getUserSubscriptionTypeSelector
} from "../store/selectors"
import { getDesktopLayout } from "../store/selectors/user/getUserData"
import moment from "moment"
import { DATE_FORMAT } from "../common/config"
import { getContentLastContact, getNameLead } from "../common/helpers"
import { isEmpty } from "lodash"
import { TodoContainer } from "../components/TodoContainer"
import { AddTodo } from "../components/add_todo"
import { TagsList } from "../components/tags/TagsList"
import JobApplySwitcher from "../components/leads/apply-tab"
import { FeaturesTypes, JOB_APPLIED, CRM_MODE } from "../common/constants"
import { PlusOutlined } from "@ant-design/icons"
import withMemo from "../store/HOC/with_memo"
import { TimelineContainer } from "./TimeLineContainer"
import { useColors } from "../hooks/useColors"
import { useTags } from "../hooks/useTags"
import { getJobs } from '../store/actions/jobs/get-jobs'
import { getContacts } from "../store/actions/contacts/get-contacts-list"
import { getCompaniesTh } from "../store/actions/companies/get-companies"
import { useActivityItem } from "../hooks/useActivityItem"
import { useAction } from "../hooks/useAction"
import EmptyNewLeadsIcon from '../assets/images/NewLeads.svg'
import EmptyAppliedLeadsIcon from '../assets/images/AppliedLeads.svg'
import EmptyContactsIcon from '../assets/images/Contacts.svg'
import EmptyCompaniesIcon from '../assets/images/Companies.svg'
import "../styles/home.css"
import TitlePopup from "../components/TitlePopup"
import { useContactSorting } from "../hooks/useContactSorting"


// import { getUserStartGuide } from "../store/selectors/user/getUserData"

const { Title } = Typography

export const Home = withMemo(() => {
  const [clickCount, setClickCount] = useState(0)
  const { allColors } = useColors()
  // useLeads()
  useTags()
  const [todo, setTodo] = useState({
    description: "",
    due_dt: 0,
  })
  const [pickerIsOpen, setPickerIsOpen] = useState(false)
  const datePickerRef = useRef()
  const addTodoRef = useRef()
  const contactsTableRef = useRef()
  const companyTableRef = useRef()
  const dispatch = useDispatch()
  const desktopLayout = useSelector(getDesktopLayout)
  const homeJobs = useSelector(getHomeJobs)
  const contacts = useSelector(getContactsSelector)
  const isContactsPending = useSelector(getContactsPendingStatus)
  const companies = useSelector(getCompaniesSelector)
  const isCompanyPending = useSelector(getCompaniesPendingStatus)
  const todosData = useSelector(getTodosSelector)
  const jobs_is_pending = useSelector(getJobPendingStatus)
  const subType = useSelector(getUserSubscriptionTypeSelector)
  const getDiariesAction = useAction(getDiaries)
  const getTodosAction = useAction(getTodos)

  const leadConfig = getNameLead(subType)

  // const startGuide = useSelector(getUserStartGuide)
  const { handleClick } = useActivityItem()

  const { handleTableChange, contactsSortKey } = useContactSorting()



  const onCloseCallback = async () => {
    try {
      await getDiariesAction()
      await getTodosAction()
    } catch (e) {
      console.log(e)
    }
  }
  const onEditLead = (lead) => {
    handleClick(lead, FeaturesTypes.LEAD, lead, { callBackFun: onCloseCallback })
  }
  const onCreateLead = () => {
    handleClick({ id: "newLead" }, FeaturesTypes.LEAD, {}, { isEdit: false, status: "add", isAdd: true, is_communication_edit: false, callBackFun: onCloseCallback })
  }

  const onCreateContact = () => {
    handleClick({ id: "newContact" }, FeaturesTypes.CONTACT, {}, { isEdit: false, status: "add", isAdd: true, is_communication_edit: false, callBackFun: onCloseCallback })
  }
  const onEditContact = (contact) => {
    handleClick(contact, FeaturesTypes.CONTACT, contact, { callBackFun: onCloseCallback })
  }

  const onCreateCompany = () => {
    handleClick({ id: "newCompany" }, FeaturesTypes.COMPANY, {}, { isEdit: false, status: "add", isAdd: true, is_communication_edit: false, callBackFun: onCloseCallback })
  }

  const onEditCompany = (company) => {
    handleClick(company, FeaturesTypes.COMPANY, company, { callBackFun: onCloseCallback })
  }


  const isBottomScroll = (e) => {
    const target = e.target;
    return (Math.round(target.scrollHeight - target.scrollTop) - 1) <= target.clientHeight;

  }

  const handleScrollContact = (e) => {
    if (isBottomScroll(e)) {
      dispatch(getContacts())
    }
  }
  const handleScrollCompany = (e) => {
    if (isBottomScroll(e)) {
      dispatch(getCompaniesTh())
    }

  }


  useEffect(() => {
    let companyTableBody,
      contactTableBody
    if (desktopLayout?.includes("leads")) {
      dispatch(getJobs())
    }
    if (desktopLayout?.includes("companies")) {
      dispatch(getCompaniesTh())
      companyTableBody = companyTableRef?.current?.querySelector('.ant-table-body')
      companyTableBody?.addEventListener('scroll', handleScrollCompany);
    }
    if (desktopLayout?.includes("contacts")) {
      dispatch(getContacts())
      contactTableBody = contactsTableRef?.current?.querySelector('.ant-table-body')
      contactTableBody?.addEventListener('scroll', handleScrollContact);
    }
    if (desktopLayout?.length === 0) {
      dispatch(getJobs())
    }
    return () => {
      companyTableBody?.removeEventListener('scroll', handleScrollCompany);
      contactTableBody?.removeEventListener('scroll', handleScrollContact);
    }
    // startGuide && handleClick({ id: "whatTodo" }, FeaturesTypes.WHAT_TODO, {}, { handleClick, startGuide })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [desktopLayout])



  const leadTableColumns = [
    {
      title: `${subType === CRM_MODE ? leadConfig.name : "Company, Position"}`,
      key: "job",
      width: "20%",
      className: "scroll-hidden-column",
      render: ({ company, title, url }) => {
        return <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <TitlePopup title={`${company?.name}${company?.name && title && ", "} ${title && title}`} />
          {url &&
            <a href={url?.includes("http") ? url : 'https://' + url} target="_blank" rel="noopener noreferrer">

              <LinkOutlined width={20}
                style={
                  {
                    marginTop: "2px",
                    color: "#009EEF",
                    fontSize: '17px',
                    cursor: "pointer"
                  }} />

            </a>
          }
        </div>
      },
    },

    {
      title: "Added",
      dataIndex: "create_dt",
      key: "create_dt",
      width: "10%",
      className: "scroll-hidden-column",
      render: (date, row) => {
        const _date = row.status_id === JOB_APPLIED ? row.apply_dt : date
        return (
          <Fragment>
            {_date && (
              <span>{moment(new Date(_date)).format(DATE_FORMAT)}</span>
            )}
          </Fragment>
        )
      },
      sorter: (val) => {
        return -new Date(val.create_dt).getTime()
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Last contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "10%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7);
        const days = totalDays % 7;
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: (a, b) => {
        return a?.seasoning - b?.seasoning
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "15%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },
    {
      title: "Status",
      dataIndex: "status_id",
      width: "10%",
      align: "center",
      render: (status, lead) => {
        return <JobApplySwitcher job={lead} />
      },
    },
  ]
  const contactsTable = [
    {
      title: "Name",
      key: "first_name",
      dataIndex: "first_name",
      width: "20%",
      className: "scroll-hidden-column",
      render: (first_name) => {
        return <div>
          <span>{first_name}</span>
        </div>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Company",
      key: "company_name",
      dataIndex: "company",
      width: "20%",
      className: "scroll-hidden-column",
      render: (company) => {
        return <div>
          <span>{company?.name}</span>
        </div>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Phone",
      key: "phone",
      dataIndex: "phone",
      width: "20%",
      className: "scroll-hidden-column",
      render: (phone) => {
        return <div>
          <span>{phone}</span>
        </div>
      },
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      width: "20%",
      className: "scroll-hidden-column",
      render: (email) => {
        return <div>
          <span>{email}</span>
        </div>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "25%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },

    {
      title: "Last contact",
      dataIndex: "seasoning",
      key: "seasoning",
      width: "20%",
      align: "center",
      render: (totalDays) => {
        const weeks = Math.floor(totalDays / 7);
        const days = totalDays % 7;
        return <span>{getContentLastContact(weeks, days)}</span>
      },
      sorter: true,
      sortDirections: ["ascend", "descend"],
    },

  ]
  const companyTable = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: "30%",
      className: "scroll-hidden-column",
      render: (name) => {
        return <div>
          <span>{name}</span>
        </div>
      },
    },
    {
      title: "Contacts",
      dataIndex: "contact_count",
      key: "contacts",
      width: "15%",
      align: "center",
      className: "scroll-hidden-column",
      sorter: (a, b) => a?.contact_count - b?.contact_count,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Notes",
      dataIndex: "note_count",
      key: "attachments",
      width: "15%",
      align: "center",
      sorter: (a, b) => a?.note_count - b?.note_count,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      width: "25%",
      render: (tags) => {
        return <TagsList tags={tags} allColors={allColors} />
      },
    },
    {
      title: "Updated",
      key: "last_edit_dt",
      dataIndex: "last_edit_dt",
      width: "20%",
      render: (date) => {
        return <span>{moment(date).format(DATE_FORMAT)}</span>
      },
      sorter: (a, b) => {
        return new Date(b.last_edit_dt) - new Date(a.last_edit_dt)
      },
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Actions",
      width: "10%",
      align: "center",
      render: (company) => {
        return (
          <Fragment>
            <EditOutlined className="cursor-pointer" />
          </Fragment>
        )
      },
    },
  ]

  const desLayout = {
    leads: (i) =>
      <div style={{ minHeight: 410 }} >
        <div
          style={{
            display: "flex",
            marginBottom: "12px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Title
            className="desktop-title"
            level={4}
            style={{ marginBottom: "0px" }}
          >
            {leadConfig.plural}
          </Title>

          <Button type="primary" size={"small"} onClick={onCreateLead}>
            <PlusOutlined />
            New
          </Button>
        </div>
        <ConfigProvider
          renderEmpty={() => (
            <div className="home-empty-lead-container">
              <div className="home-empty-contact-content">
                <div className="home-empty-image-container"><img src={EmptyNewLeadsIcon} alt="no new leads" className="home-empty-image" /></div>
                <span className="home-empty-text">
                  This is where you track new {leadConfig.plural.toLowerCase()}. {leadConfig.plural} to apply to,
                  engagements to explore, deals to pursue. <br />Every {leadConfig.name.toLowerCase()} matters.</span>
              </div>

              <div className="home-empty-lead-content">
                <div className="home-empty-image-container"><img src={EmptyAppliedLeadsIcon} alt="no applied leads" className="home-empty-image" /> </div>
                <span className="home-empty-lead-text" >
                  Tracking {leadConfig.plural.toLowerCase()} where you’ve taken the first step.
                  What’s the latest? What are the next steps?
                  <div style={{ transform: "translateX(-4px)" }}> Don’t lose track, stay on top of your active bucket.</div>
                </span>
              </div>

            </div>
          )}
        >
          <Table
            showHeader={homeJobs?.length > 0}
            size="small"
            className={homeJobs?.length === 0 ? "empty-home-leads-table" : ""}
            loading={{ indicator: <Spin style={{ top: desktopLayout?.length > 1 ? "50%" : "79%" }} />, spinning: jobs_is_pending }}
            rowKey={(row) => row.id}
            columns={leadTableColumns}
            dataSource={homeJobs}
            scroll={{ y: desktopLayout?.length > 1 ? i === 1 ? "36vh" : "32vh" : "78vh" }}
            pagination={false}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  e.preventDefault()
                  onEditLead(record)
                },
              }
            }}
          />
        </ConfigProvider>
      </div >
    ,
    companies: (i) => <div style={{
      minHeight: 410
    }}>
      <div
        style={{
          display: "flex",
          marginBottom: "12px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          className="desktop-title"
          level={4}
          style={{ marginBottom: "0px" }}
        >
          Companies
        </Title>
        <Button onClick={onCreateCompany} type="primary" size={"small"}>
          <PlusOutlined />
          New
        </Button>
      </div>
      <div ref={companyTableRef}>
        <ConfigProvider
          renderEmpty={() => (
            <div className="home-empty-container">
              <div className="home-empty-contact-content">
                <div className="home-empty-image-container"><img src={EmptyCompaniesIcon} alt="no companies" className="home-empty-image" /></div>
                <span className="home-empty-text">
                  “It’s the economy, ******”.
                  Whether you are covering an industry or exploring potential employers,
                  company research is essential.</span>
              </div>
            </div>
          )}
        >
          <Table
            showHeader={companies?.length > 0}
            className={companies?.length === 0 ? "empty-home-leads-table" : ""}
            size="small"
            loading={isCompanyPending}
            rowKey={(row) => row.id}
            columns={companyTable}
            dataSource={companies}
            scroll={{ y: desktopLayout?.length > 1 ? i === 1 ? "36vh" : "33vh" : "78vh" }}
            pagination={false}
            onRow={(record, dataIndex) => {
              return {
                onClick: () => {
                  onEditCompany(record)
                },
              }
            }}
          />
        </ConfigProvider>
      </div>
    </div>,
    contacts: (i) => <div style={{ minHeight: 410 }}>
      <div
        style={{
          display: "flex",
          marginBottom: "12px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Title
          className="desktop-title"
          level={4}
          style={{ marginBottom: "0px" }}
        >
          Contacts
        </Title>
        <Button onClick={onCreateContact} type="primary" size={"small"}>
          <PlusOutlined />
          New
        </Button>
      </div>
      <div style={{ marginBottom: 20, ...(contacts?.length > 0 ? {} : { height: "32vh" }) }} ref={contactsTableRef}>
        <ConfigProvider
          renderEmpty={() => (
            <div className="home-empty-container">
              <div className="home-empty-contact-content">
                <div className="home-empty-image-container"><img src={EmptyContactsIcon} alt="no contacts" className="home-empty-image" /></div>
                <span className="home-empty-text">
                  Because “it’s who you know”.
                  Track your contacts, record your interactions,
                  get periodic reminders to reach out,
                  build your network.</span>
              </div>
            </div>
          )}
        >
          <Table
            size="small"
            className={contacts?.length === 0 ? "empty-home-leads-table" : ""}
            showHeader={contacts?.length > 0 || contactsSortKey !== null}
            loading={isContactsPending}
            rowKey={(row) => row.id}
            columns={contactsTable}
            dataSource={contacts}
            onChange={handleTableChange}
            scroll={{ y: desktopLayout?.length > 1 ? i === 1 ? "36vh" : "32vh" : "78vh" }}
            pagination={false}
            onRow={(record, dataIndex) => {
              return {
                onClick: () => {
                  onEditContact(record)
                },
              }
            }}
          />
        </ConfigProvider>
      </div>
    </div>,
  }


  useEffect(() => {
    document.title = "Savvyy"
    if (isEmpty(todosData)) {
      dispatch(getTodos())
    }
    //eslint-disable-next-line
  }, [dispatch])
  useEffect(() => {
    if (pickerIsOpen || clickCount === 3) {
      datePickerRef.current?.focus()
    }
  }, [pickerIsOpen, clickCount])
  return (
    <Row span={24} gutter={[16, 16]} className="desktop-container">
      <Col span={12} >
        {desktopLayout?.length > 0 ? desktopLayout?.map((item, index) => (<div key={index} >{desLayout[item](index)}</div>)) :
          <div>{desLayout?.leads(0)}</div>}
      </Col>
      <Col span={12} >
        <TimelineContainer />
        <div style={{ marginBottom: 20 }} />
        <AddTodo
          setClickCount={setClickCount}
          setTodo={setTodo}
          clickCount={clickCount}
          todo={todo}
          addTodoRef={addTodoRef}
          setPickerIsOpen={setPickerIsOpen}
          pickerIsOpen={pickerIsOpen}
        />
        <TodoContainer />
      </Col>
    </Row >
  )
})
