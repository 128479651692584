import { api } from "./config"
import { URLS } from "./urls"

export const SEARCH_MODE = "SEARCH"
export const CRM_MODE = "CRM"

export const nameLead = {
  [CRM_MODE]: {
    name: "Lead",
    plural: "Leads",
    applied: "Active",
    discard: "Done",
    done: "Done",
  },
  [SEARCH_MODE]: {
    name: "Job",
    plural: "Jobs",
    applied: "Applied",
    discard: "Discard",
    done: "Discarded",
  },
}

export const TODO_DONE_STATUS = 2
export const TODO_NOT_DONE_STATUS = 1
export const JOB_DISCARDED = 3
export const JOB_APPLIED = 2
export const JOB_NOT_APPLIED = 1
export const ACCOUNT_CONFIRMATION = "account-confirm-email"
export const PASSWORD_RESET = "password-reset"
export const PASSWORD_RESET_SUCCESS_CONTENT =
  "Thanks your password has been successfully changes please log in "
export const EMAIL_CONFIRMED_MESSAGE =
  "Thank you for successfully verifying your account"
export const communicationsTypes = {
  1: {
    type: "Email",
  },
  2: {
    type: "Phone",
  },
  3: {
    type: "Meeting",
  },
  4: {
    type: "Mail",
  },
}

export const redirectExclusionUrls = [
  URLS.ROOT,
  URLS.LOGIN,
  URLS.FORGOT_PASS,
  URLS.FORGOT_PASS_CONFIRM,
  URLS.REGISTER,
]

export const SEARCH_PREFIXES = [
  "#",
  // "@"
]

export const sortContactKeys = [
  "first_name",
  "company_name",
  "email",
  "city",
  "seasoning",
]
export const subscriptionProcessors = {
  STRIPE: "STRIPE",
  APPLE: "APPLE",
}
export const FeaturesTypes = {
  workspace: "workspace",
  DIARY: "diary",
  LEAD: "job",
  CONTACT: "contact",
  COMPANY: "company",
  COMMUNICATION: "communication",
  NOTE: "note",
  FILES: "files",
  LINK: "link",
  WHAT_TODO: "whatToDo",
  MINI_COMMUNICATION: "miniConvo",
  FILE_UPLOAD: "fileUpload",
  TODO: "todo_due",
  TODO_DONE: "todo_done",
}

export const LEAD_STATUSES = {
  NEW: "New",
  APPLIED: "Applied",
  DISCARDED: "Discarded",
}

export const CUSTOM_ERROR_MESSAGE = "Something went wrong, please try again"
export const CONNECTION_ISSUE_MESSAGE =
  "Something went wrong, please check your internet connection"
//api urls

export const NOTE_API_URL = `${api.host}${api.urls.notes}`
export const LINKS_API_URL = `${api.host}${api.urls.link}`
export const COLORS_API_URL = `${api.host}${api.urls.colors}`
export const COMMUNICATION_API_URL = `${api.host}${api.urls.communication}`
export const COMPANY_API_URL = `${api.host}${api.urls.company}`
export const COMPANY_SEARCH_API_URL = `${api.host}${api.urls.company_search}`
export const CONTACT_API_URL = `${api.host}${api.urls.contact}`
export const TODOS_API_URL = `${api.host}${api.urls.todos}`
export const JOB_API_URL = `${api.host}${api.urls.job}`
export const DIARIES_TODAY_API_URL = `${api.host}${api.urls.diary_today}`
export const DIARIES_API_URL = `${api.host}${api.urls.diaries}`
export const FILES_API_URL = `${api.host}${api.urls.files_list}`
export const IMPORT_API_URL = `${api.host}${api.urls.import}`
export const MENTIONS_API_URL = `${api.host}${api.urls.mentions}`
export const SEARCH_API_URL = `${api.host}${api.urls.search}`
export const TAGS_API_URL = `${api.host}${api.urls.tags}`
export const TAG_API_URL = `${api.host}${api.urls.tag}`
export const DRAG_TODOS_API_URL = `${api.host}${api.urls.drag_todos}`
export const PROFILE_VERIFY_API_URL = `${api.auth_host}${api.urls.verify_profile}`
export const UPDATE_PROFILE_EMAIL_API_URL = `${api.auth_host}${api.urls.update_profile_email}`
export const LOGIN_API_URL = `${api.auth_host}login/`
export const REGISTER_USER_API_URL = `${api.auth_host}registration/`
export const VERIFY_TOKEN_API_URL = `${api.auth_host}token/verify/`
export const PROFILE_API_URL = `${api.auth_host}${api.urls.me}`
export const DELETE_PROFILE_API_URL = `${api.host}${api.urls.profile}`
export const EXPORT_DATA_API_URL = `${api.host}${api.urls.export}`
export const CONFIRM_USER_EMAIL_API_URL = `${api.auth_host}${api.urls.confirm_email}`
export const REFRESH_TOKEN_API_URL = `${api.auth_host}${api.urls.refresh_token}`
export const PASSWORD_RESET_API_URL = `${api.auth_host}${api.urls.reset_password}`
export const PASSWORD_RESET_CONFIRM_API_URL = `${api.auth_host}${api.urls.reset_password_confirm}`
export const GOOGLE_LOGIN_API_URL = `${api.auth_host}${api.urls.google_login}`
export const APPLE_LOGIN_API_URL = `${api.auth_host}${api.urls.apple_login}`
export const LINKEDIN_LOGIN_API_URL = `${api.auth_host}${api.urls.linkedin_login}`
export const DIARIES_ACTIVITY_TYPE = `${api.host}${api.urls.diary_activity}`
export const TODO_CLEAR_ALL_API_URL = `${api.host}${api.urls.todo_clear_active}`
export const COMPANY_DETAILS_API_URL = `${api.host}${api.urls.company_details}`
export const WORKSPACE_API_URL = `${api.host}${api.urls.workspaces}`
export const WORKSPACE_NOTES_API_URL = `${api.host}${api.urls.workspace_note}`
export const PAGE_TITLE_API_URL = `${api.host}${api.urls.page_title_by_url}`
export const STRIPE_API_URL = `${api.host}${api.urls.stripe}`
export const SUBSCRIPTION_API_URL = `${api.host}${api.urls.subscription}`
export const SUPPORT_API_URL = `${api.host}${api.urls.support}`
